.content{
  width: 100%;
  height: 100%;
}

.gridItem {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0 30px 0 30px;
}

.img {
  max-width: 100%;
  height: auto;
}

.card{
  background-color: #FFCDB2 !important;
  border: 20px #E5989B solid;
  margin: 10px;
}

.text{
  font-family: "Bree", cursive;
  font-size: 16px;
}