@import "~react-image-gallery/styles/scss/image-gallery.scss";

@font-face {
  font-family: "Bree";
  src: url("assets/fonts/Bree.otf") format("opentype");
}

@font-face {
  font-family: "Suave Script Pro Bold";
  src: url("/assets/fonts/suave-script-pro-bold.otf") format("opentype");
}

html {
  background-color: #FFB4A2;
  background-image: url(assets/img/background-overlay.png);
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

html, body{
  width: 100%;
  height: 100%;
  //background-color: #B5838D;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-color: #B5838D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  width: 100%;
  height: 100%;
}

.css-18lrjg1-MuiCircularProgress-root{
  width: 10rem !important;
  height: 10rem !important;
}

.vertical-timeline::before{
  background: #6d6875 !important;
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #B5838D,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05) !important;
}

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
  font-size: 1.2rem !important;
  font-family: "Bree", cursive;
}