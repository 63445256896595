.content{
  width: 100%;
  height: 100%;
}

.buttonPanel{
  position: fixed;
  width: 100%;
  z-index: 2;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  margin-bottom: 1rem;
}

.buttonPanelButtons {
  background-color: rgba(52, 51, 48, 0.95);
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}


.buttonPanelButton {
  font-size: 2rem;
  color: #ffcdb2;
}

.buttonPanelButtonIcon {
  font-size: 2rem;
  color: #B5838D;
}

.cardList{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem 5rem;
}

.grid{
  display: flex;
  align-items: center;
  justify-content: center;
}

.gridItem{
  display: flex;
  align-items: center;
  justify-content: center;
}