.card{
  position: relative;
  border: 5px #E5989B solid;
}

.cardActionArea {
  position: relative !important;
}

.cardMedia {
  position: relative;
}

.cardContent {
  position: absolute;
  bottom: 0;
  background-color: rgba(52, 51, 48, 0.8);
  color: white;
  width: 100%;
  padding: 0 !important;
}

.modalCard {
  background-color: #1f1f1f;
}

.modalImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}