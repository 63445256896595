.content{
  width: 100%;
  height: 100%;
  //justify-content: center;
}

.header{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Suave Script Pro Bold", cursive;
  height: 20rem;
  font-size: 1.5rem;
  color: #FFE4D6;
  -webkit-text-stroke: 1.3px #6D6875;
}

.timelineContainer{
  display: flex;
  width: 100%;
  justify-content: center;
}

.timeline{
  margin: 50px 60px;
  height: 1000px;
  background-color: #ccc;
  width: 1rem;
}

.timelineLine {
  width: 100%;
  height: 33%;
  background-color: orange;
}

.timelineGridItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timelineContentCard{
  border: 10px #ffcdb2 solid;
  font-family: "Bree", cursive;
}

.photo {
  max-width: 300px;
  height: auto;
  border: 5px #ffcdb2 solid;
  border-radius: 10px;
}