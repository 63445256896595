.content{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader{
  color: #B5838D !important;
  font-size: 3rem;
}