.navbar{
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: rgba(181, 131, 141, 0.85);
  z-index: 999;
  top: 0;
}

.logo {
  margin: 0 20px 0 20px;
  max-height: 70px;
  width: auto;
}

.navMenuButton{
  color: #6D6875;
}

.navItemsList{
  width: 300px;
  display: flex;
  justify-content: space-evenly;
}

.menuIcon {
  font-size: 30px;
  color: #343330;
}